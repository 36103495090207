import React from 'react';
import styled from '@emotion/styled';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Link from './Link';
import ArrowIcon from '../images/icons/icon-arrow.svg';

import { colors } from '../theme/colors';
import { body, headers, uppercase } from '../styles/type';
import { fontWeights } from '../theme/type';
import { rem } from '../utilities/style';
import { media } from '../theme/media';

const Image = styled(GatsbyImage)`
  filter: grayscale(1);
  transition: filter 200ms;
  aspect-ratio: 15/10;
  
  ${media('>tiny')} {
    @supports (-webkit-touch-callout: none) {
      height: 166.66px;
      object-fit: cover;
    }
  }
`;

const Container = styled.article`
  position: relative;
  padding: ${rem(30)} 0px;

  ${media('>tiny')} {
    display: flex;
  }

  &:hover ${Image} {
    filter: grayscale(0);
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid ${colors.lightGray};
    opacity: 0.2;
  }
`;

const ImageContent = styled.div`
    margin: 0px 0px ${rem(20)} 0px;

    ${media('>tiny')} {
    flex: 0 1 30%;
      margin: 0px ${rem(40)} 0px 0px;
    }

    ${media('>small')} {
      flex: 0 1 ${rem(250)};
      margin: 0px ${rem(40)} 0px 0px;
    }
`;

const TextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  display: flex;
  ${uppercase}
  ${body.tiny}
  font-weight: ${fontWeights.medium};
  color: ${colors.gray};
  padding-bottom: ${rem(12)};
  margin: ${rem(-2)} ${rem(-8)};

  ${media('>tiny')} {
    padding-bottom: ${rem(16)};
    margin: ${rem(-2)} ${rem(-12)};
  }

  ${media('>smedium')} {
    ${body.xsmall}
    padding-bottom: ${rem(24)};
    margin: ${rem(-2)} ${rem(-20)};
  }

  p {
    margin: ${rem(2)} ${rem(8)};

    ${media('>tiny')} {
      margin: ${rem(2)} ${rem(12)};
    }

    ${media('>smedium')} {
      margin: ${rem(2)} ${rem(20)};
    }
  }
`;

const Category = styled.p`
`;

const Date = styled.p`
`;

const Headline = styled.h2`
  ${headers.h6}
  max-width: ${rem(600)};
  font-weight: ${fontWeights.semiBold};
  line-height: 100%;

  ${media('>small')} {
    ${headers.h5}
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    inset: 0;
  }
`;

const StyledArrowIcon = styled(ArrowIcon)`
  display: block;
  padding-top: ${rem(8)};
  margin-top: auto;
  margin-left: auto;
  transition: color 200ms;
  width: ${rem(16)};


  ${Container}:hover &,
  ${Container}:focus-within & {
    color: ${colors.purple};
  }
`;

const Post = ({ image, category, date, title, slug }) => (
  <Container>
    <ImageContent>
      <Image
        image={getImage(image.asset)}
        alt={image.asset.altText}
        objectFit="cover"
        objectPosition="top center"
      />
    </ImageContent>
    <TextContent>
      <Info>
        <Category>{category}</Category>
        <Date>{date}</Date>
      </Info>
      <Headline>
        <StyledLink to={`/thoughts/${slug.current}`} targetBlank>{title}</StyledLink>
      </Headline>
      <StyledArrowIcon />
    </TextContent>
  </Container>
);

export default Post;
