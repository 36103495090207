import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql, navigate } from 'gatsby';

import Layout from '../components/Layout';
import Header from '../components/blocks/Header';
import FlexibleBlock from '../components/FlexibleBlock';
import Seo from '../components/Seo';
import Post from '../components/Post';
import FeaturedPost from '../components/FeaturedPost';

import { containerStyles } from '../styles/layout';
import { colors } from '../theme/colors';
import { body, uppercase } from '../styles/type';
import { fontWeights } from '../theme/type';
import { rem } from '../utilities/style';
import { formatDate } from '../utilities/misc';
import { media } from '../theme/media';

const Container = styled.div`
  ${containerStyles.regular}
`;

const Filters = styled.nav`
  padding-top: ${rem(64)};
  border-bottom: ${rem(3)} solid ${colors.white};

  ${media('>=small')} {
    padding-top: ${rem(120)};
  }
`;

const FilterButton = styled.button`
  ${uppercase}
  ${body.tiny}
  padding: ${rem(15)} ${rem(15)};
  font-weight: ${fontWeights.bold};
  background-color: ${colors.black};
  color: ${colors.white};
  border: none;
  cursor: pointer;
  transition: background-color 200ms, color 200ms;

  &:hover {
    background-color: ${colors.white};
    color: ${colors.black};
  }

  ${({ filter, activeFilter }) => filter === activeFilter && css`
    background-color: ${colors.white};
    color: ${colors.black};
  `}
`;

const Posts = styled.div`
`;

const PostsPage = ({ data, location }) => {
  const featuredPost = data.sanityFeaturedPost.post;
  const allPosts = data.allSanityPost.nodes;
  const { blocks } = data.sanityListingPage;
  const [posts, setPosts] = useState(allPosts);
  const [activeFilter, setActiveFilter] = useState('all');

  const filterPosts = useCallback((filter) => {
    const filteredPosts = allPosts.filter(({ category, _id: id }) => {
      let includeInResult = true;
      if (filter !== 'all') {
        includeInResult = category === filter;
      }
      return includeInResult && id !== featuredPost._id;
    });
    setPosts(filteredPosts);
  }, [allPosts, featuredPost]);

  const filterChange = (filter) => {
    if (filter === 'all' || filter === activeFilter) {
      setActiveFilter('all');
      filterPosts('all');
      navigate(`${location.pathname}#filters`);
      return;
    }
    setActiveFilter(filter);
    filterPosts(filter);
    navigate(`${location.pathname}?category=${filter}#filters`);
  };

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const filter = urlParams.get('category') || 'all';
    setActiveFilter(filter);
    filterPosts(filter);
  }, [filterPosts, location.search]);

  return (
    <Layout
      scrollBehavior="smooth"
    >
      <Header headline="Thoughts" />
      <Container>
        <FeaturedPost
          title={featuredPost.title}
          image={featuredPost.image}
          category={featuredPost.category}
          date={formatDate(featuredPost.date)}
          slug={featuredPost.slug}
        />
        <Filters id="filters">
          <FilterButton activeFilter={activeFilter} filter="all" onClick={() => (filterChange('all'))}>all</FilterButton>
          <FilterButton activeFilter={activeFilter} filter="culture" onClick={() => (filterChange('culture'))}>culture</FilterButton>
          <FilterButton activeFilter={activeFilter} filter="insights" onClick={() => (filterChange('insights'))}>insights</FilterButton>
          <FilterButton activeFilter={activeFilter} filter="work" onClick={() => (filterChange('work'))}>work</FilterButton>
          <FilterButton activeFilter={activeFilter} filter="press" onClick={() => (filterChange('press'))}>press</FilterButton>
        </Filters>
        <Posts>
          {posts.map(({ image, category, date, title, slug, _id }) => (
            <Post
              key={_id}
              image={image}
              category={category}
              date={formatDate(date)}
              title={title}
              slug={slug}
            />
          ))}
        </Posts>
      </Container>
      {blocks.map((block, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FlexibleBlock key={index} type={block._type} data={block} />
      ))}
    </Layout>
  );
};

export const Head = ({ data }) => {
  const { seo } = data.sanityListingPage;
  return (
    <Seo
      title={seo?.title}
      description={seo?.description}
      openGraphTitle={seo?.openGraphTitle || seo?.title}
      openGraphDescription={seo?.openGraphDescription || seo?.description}
      openGraphImage={seo?.openGraphImage?.asset.url}
      noFollowNoIndex={seo?.noFollowNoIndex}
    />
  );
};

export const query = graphql`
query Posts {
  allSanityPost(sort: {fields: date, order: DESC}) {
    nodes {
      title
      category
      date
      _id
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
          altText
        }
      }
    }
  }

  sanityFeaturedPost {
    post {
      _id
      title
      date
      category
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }

  sanityListingPage(_id: {eq: "blogListingPage"}) {
    blocks {
      ...FlexibleBlockFragment
    }
    seo {
      ...SeoFragment
    }
  }
}`;

export default PostsPage;
