import React from 'react';
import styled from '@emotion/styled';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ArrowIcon from '../images/icons/icon-arrow.svg';
import PinIcon from '../images/icons/icon-pin.svg';
import Link from './Link';

import { colors } from '../theme/colors';
import { body, headers, uppercase } from '../styles/type';
import { fontWeights } from '../theme/type';
import { rem } from '../utilities/style';
import { media } from '../theme/media';

const Container = styled.article`
`;

const Content = styled.div`
  position: relative;

  ${media('>small')} {
    display: flex;
    justify-content: space-between;
  }
`;

const ImageContent = styled.div`
    flex: 0 1 ${rem(500)};
    margin: 0px 0px ${rem(20)} 0px;

    ${media('>small')} {
      margin: 0px ${rem(40)} 0px 0px;
    }
`;

const StyledPinIcon = styled(PinIcon)`
  margin-right: ${rem(20)};
`;

const Subheading = styled.div`
  ${body.xsmall}
  ${uppercase}
  font-weight: ${fontWeights.medium};
  color: ${colors.gray};
  margin-bottom: ${rem(30)};
`;

const Image = styled(GatsbyImage)`
  aspect-ratio: 15/10;
`;

const TextContent = styled.div`
  flex: 0 1 ${rem(600)};
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  ${uppercase}
  ${body.xsmall}
  display: flex;
  flex-wrap: wrap;
  font-weight: ${fontWeights.medium};
  color: ${colors.gray};
  padding-bottom: ${rem(12)};
  margin: ${rem(-2)} ${rem(-8)};

  ${media('>tiny')} {
    ${body.small}
    padding-bottom: ${rem(16)};
    margin: ${rem(-2)} ${rem(-12)};
  }

  ${media('>smedium')} {
    padding-bottom: ${rem(24)};
    margin: ${rem(-2)} ${rem(-20)};
  }

  p {
    margin: ${rem(2)} ${rem(8)};

    ${media('>tiny')} {
      margin: ${rem(2)} ${rem(12)};
    }

    ${media('>smedium')} {
      margin: ${rem(2)} ${rem(20)};
    }
  }
`;

const Category = styled.p`
`;

const Date = styled.p`
`;

const Headline = styled.h2`
  ${headers.h6}
  font-weight: ${fontWeights.semiBold};
  line-height: 100%;

  ${media('>tiny')} {
    ${headers.h5}
  }

  ${media('>small')} {
    ${headers.h3}
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    inset: 0;
  }
`;

const StyledArrowIcon = styled(ArrowIcon)`
  display: block;
  padding-top: ${rem(8)};
  margin-top: auto;
  margin-left: auto;
  transition: color 200ms;
  width: ${rem(16)};

  ${Content}:hover &,
  ${Content}:focus-within & {
    color: ${colors.purple};
  }
`;

const FeaturedPost = ({ title, image, category, date, slug }) => (
  <Container>
    <Subheading>
      <StyledPinIcon />
      pinned thought
    </Subheading>
    <Content>
      <ImageContent>
        <Image
          image={getImage(image.asset)}
          alt={image.asset.altText}
          objectFit="cover"
          objectPosition="center"
        />
      </ImageContent>
      <TextContent>
        <Info>
          <Category>{category}</Category>
          <Date>{date}</Date>
        </Info>
        <Headline>
          <StyledLink to={`/thoughts/${slug.current}`}>
            {title}
          </StyledLink>
        </Headline>
        <StyledArrowIcon />
      </TextContent>
    </Content>
  </Container>
);

export default FeaturedPost;
